import {
  FIRST_NAME_IS_INVALID,
  LAST_NAME_IS_INVALID,
  EMAIL_IS_INVALID,
  PHONE_NUMBER_IS_INVALID,
  SSN_IS_INVALID,
  DATE_OF_BIRTH_IS_INVALID,
  DATE_OF_BIRTH_IS_GREATER,
  DATE_OF_BIRTH_IS_LESSER,
  ANNUAL_INCOME_IS_INVALID,
  YOU_HAVE_TO_ACCEPT_THIS_LICENSE_AGREEMENT,
  ZIP_CODE_IS_INVALID,
  RENT_IS_INVALID,
  REASON_FOR_LEAVING_IS_INVALID,
  MOVE_IN_DATE_IS_INVALID,
  MOVE_IN_DATE_IS_LATER,
  MOVE_OUT_DATE_IS_INVALID,
  MOVE_OUT_DATE_IS_EARLIER,
  STREET_ADDRESS_1_IS_INVALID,
  STREET_ADDRESS_2_IS_INVALID,
  CITY_IS_INVALID,
  STATE_IS_INVALID,
  COUNTRY_IS_INVALID,
  LANDLORD_NAME_IS_INVALID,
  LANDLORD_PHONE_NUMBER_IS_INVALID,
  NO_RENTAL_HISTORY_ERROR,
  ADDRESS_IS_INVALID,
  ZIP_CODE_IS_REQUIRED,
} from '~/assets/strings';
import { PresentableEmploymentInformation, PresentableRentalHistory } from '~/presenters/RentalApplicationPresenter';
import { RentalApplicationCredentials } from '~/state/mainAppState';
import { Address } from './Address';

export interface updateRentalApplicationParams {
  rentalApplicationId: string;
  rentalApplicationPW: string;
  applicationData: RentalApplicationData;
}

export interface RentalApplicationData {
  name: string;
  email: string;
  phone: string;
  ssn: string;
  tenantId: string;
  applyingWith: string[];
  interestedUnitIds: string[];
  desiredMoveInDate: string;
  maritalStatus: MaritalStatus;
  driversLicense: DriversLicense;
  incomeInformation: IncomeInformation;
  pets: Pet[];
  residentialHistory: RentalHistory[];
  emergencyContact: EmergencyContact;
  employment: EmploymentInformation;
  questions: Record<string, boolean>;
  password: string;
}

export interface IncomeInformation {
  annualIncome?: number;
}

export interface EmploymentInformation {
  name: string;
  phone: string;
  position: string;
  salary?: number;
  startDate?: string;
  endDate?: string;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export enum TransUnionScreeningStatus {
  NotStarted = 'notStarted',
  Verified = 'verified',
  UnVerified = 'unVerified',
  ManualVerificationRequired = 'manualVerificationRequired',
}

export interface TransUnionScreening {
  status: TransUnionScreeningStatus;
  transunionScreeningId: number;
  transunionScreeningRequestRenterId: number;
  examId?: number;
  questions?: TransUnionExamQuestion[];
}

export interface TransUnionExamQuestion {
  questionKeyName: string;
  questionDisplayName: string;
  type?: string;
  choices?: TransUnionQuestionChoice[];
}

export interface TransUnionQuestionChoice {
  choiceKeyName?: string;
  choiceDisplayName?: string;
}

export enum MaritalStatus {
  Single = 'single',
  Married = 'married',
  Divorced = 'divorced',
  Widowed = 'widowed',
}

export interface DriversLicense {
  number?: string;
  state?: string;
  country?: string;
}

export interface EmergencyContact {
  name?: string;
  phone?: string;
  email?: string;
  relationship?: string;
}

export interface Pet {
  name?: string;
  breed?: string;
  weight?: string;
  age?: number;
  gender?: Gender;
}

export enum Gender {
  Male = 'male',
  Female = 'female',
}

export interface RentalApplicationComment {
  comment: string;
  section: CommentSection;
}

export enum CommentSection {
  ApplicantInformation = 'applicantInformation',
  Questions = 'questions',
  IntrestedUnits = 'intrestedUnits',
  RentalHistory = 'rentalHistory',
  WorkHistory = 'workHistory',
  Screening = 'screening',
  Other = 'other',
}

export enum RentalApplicationPaymentStatus {
  Unpaid = 'unpaid',
  Processing = 'processing',
  Paid = 'paid',
  Failed = 'failed',
  Refunded = 'refunded',
}

export interface RentalApplication {
  credentials: RentalApplicationCredentials;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  ssn?: string;
  tenantId?: string;
  dateOfBirth?: string;
  applyingWith?: string[];
  interestedUnits?: string[];
  desiredMoveInDate?: string;
  maritalStatus?: MaritalStatus;
  driversLicense?: DriversLicense;
  annualIncome?: number;
  pets?: Pet[];
  residentialHistory?: RentalHistory[];
  emergencyContact?: EmergencyContact;
  employmentHistory?: EmploymentInformation[];
  comments?: RentalApplicationComment[];
  questionsAndAnswers?: QuestionAndAnswers[];
  settings?: RentalApplicationSettings;
  createdAt?: string;
  updatedAt?: string;
  files?: HydratedRentalApplicationFileDto[];
  paymentStatus?: RentalApplicationPaymentStatus;
  isDraft?: boolean;
  submittedAt?: string;
  requiresPayment?: boolean;
  screeningStatus?: TransUnionScreeningStatus;
  newFiles: FileDescriptor[];
  hasAcceptedMagicDoorTerms?: boolean;
  hasAcceptedTransUnionTerms?: boolean;
}

export enum RentalApplicationErrorCode {
  InvalidFirstName,
  InvalidLastName,
  InvalidEmail,
  InvalidPhone,
  InvalidSSN,
  InvalidDateOfBirth,
  InvalidAnnualIncome,
  InvalidMagicDoorAgreement,
  InvalidTransUnionAgreement,
  InvalidDateOfBirthGreater,
  InvalidDateOfBirthLesser,
}

export const applicantInfoErrors = [
  RentalApplicationErrorCode.InvalidFirstName,
  RentalApplicationErrorCode.InvalidLastName,
  RentalApplicationErrorCode.InvalidEmail,
  RentalApplicationErrorCode.InvalidPhone,
  RentalApplicationErrorCode.InvalidSSN,
  RentalApplicationErrorCode.InvalidDateOfBirth,
  RentalApplicationErrorCode.InvalidDateOfBirthGreater,
  RentalApplicationErrorCode.InvalidDateOfBirthLesser,
];

export const errorMessagesForRA = {
  [RentalApplicationErrorCode.InvalidFirstName]: FIRST_NAME_IS_INVALID,
  [RentalApplicationErrorCode.InvalidLastName]: LAST_NAME_IS_INVALID,
  [RentalApplicationErrorCode.InvalidEmail]: EMAIL_IS_INVALID,
  [RentalApplicationErrorCode.InvalidPhone]: PHONE_NUMBER_IS_INVALID,
  [RentalApplicationErrorCode.InvalidDateOfBirth]: DATE_OF_BIRTH_IS_INVALID,
  [RentalApplicationErrorCode.InvalidDateOfBirthGreater]: DATE_OF_BIRTH_IS_GREATER,
  [RentalApplicationErrorCode.InvalidDateOfBirthLesser]: DATE_OF_BIRTH_IS_LESSER,
  [RentalApplicationErrorCode.InvalidAnnualIncome]: ANNUAL_INCOME_IS_INVALID,
  [RentalApplicationErrorCode.InvalidSSN]: SSN_IS_INVALID,
  [RentalApplicationErrorCode.InvalidMagicDoorAgreement]: YOU_HAVE_TO_ACCEPT_THIS_LICENSE_AGREEMENT,
  [RentalApplicationErrorCode.InvalidTransUnionAgreement]: YOU_HAVE_TO_ACCEPT_THIS_LICENSE_AGREEMENT,
};

export enum RentalHistoryErrorCode {
  NoHistory,
  InvalidAddress,
  InvalidRentalHistoryStreetAddress1,
  InvalidRentalHistoryStreetAddress2,
  InvalidRentalHistoryReasonForLeaving,
  InvalidRentalHistoryCity,
  InvalidRentalHistoryState,
  InvalidRentalHistoryZipCode,
  InvalidRentalHistoryCountry,
  InvalidRentalHistoryLandlordName,
  InvalidRentalHistoryLandlordPhone,
  InvalidRentalHistoryRent,
  InvalidRentalHistoryMoveInDate,
  InvalidRentalHistoryMoveInDateLater,
  InvalidRentalHistoryZipCodeRequired,
  InvalidRentalHistoryMoveOutDate,
  InvalidRentalHistoryMoveOutDateEarlier,
}

export const rentalHistoryErrors = [
  RentalHistoryErrorCode.NoHistory,
  RentalHistoryErrorCode.InvalidAddress,
  RentalHistoryErrorCode.InvalidRentalHistoryLandlordName,
  RentalHistoryErrorCode.InvalidRentalHistoryLandlordPhone,
  RentalHistoryErrorCode.InvalidRentalHistoryRent,
  RentalHistoryErrorCode.InvalidRentalHistoryReasonForLeaving,
  RentalHistoryErrorCode.InvalidRentalHistoryMoveInDate,
  RentalHistoryErrorCode.InvalidRentalHistoryMoveInDateLater,
  RentalHistoryErrorCode.InvalidRentalHistoryMoveOutDate,
  RentalHistoryErrorCode.InvalidRentalHistoryMoveOutDateEarlier,
  RentalHistoryErrorCode.InvalidRentalHistoryStreetAddress1,
  RentalHistoryErrorCode.InvalidRentalHistoryStreetAddress2,
  RentalHistoryErrorCode.InvalidRentalHistoryCity,
  RentalHistoryErrorCode.InvalidRentalHistoryState,
  RentalHistoryErrorCode.InvalidRentalHistoryZipCodeRequired,
  RentalHistoryErrorCode.InvalidRentalHistoryZipCode,
  RentalHistoryErrorCode.InvalidRentalHistoryCountry,
];

export const errorMessagesForRH = {
  [RentalHistoryErrorCode.NoHistory]: NO_RENTAL_HISTORY_ERROR,
  [RentalHistoryErrorCode.InvalidAddress]: ADDRESS_IS_INVALID,
  [RentalHistoryErrorCode.InvalidRentalHistoryLandlordName]: LANDLORD_NAME_IS_INVALID,
  [RentalHistoryErrorCode.InvalidRentalHistoryLandlordPhone]: LANDLORD_PHONE_NUMBER_IS_INVALID,
  [RentalHistoryErrorCode.InvalidRentalHistoryRent]: RENT_IS_INVALID,
  [RentalHistoryErrorCode.InvalidRentalHistoryReasonForLeaving]: REASON_FOR_LEAVING_IS_INVALID,
  [RentalHistoryErrorCode.InvalidRentalHistoryMoveInDate]: MOVE_IN_DATE_IS_INVALID,
  [RentalHistoryErrorCode.InvalidRentalHistoryMoveInDateLater]: MOVE_IN_DATE_IS_LATER,
  [RentalHistoryErrorCode.InvalidRentalHistoryMoveOutDate]: MOVE_OUT_DATE_IS_INVALID,
  [RentalHistoryErrorCode.InvalidRentalHistoryMoveOutDateEarlier]: MOVE_OUT_DATE_IS_EARLIER,
  [RentalHistoryErrorCode.InvalidRentalHistoryStreetAddress1]: STREET_ADDRESS_1_IS_INVALID,
  [RentalHistoryErrorCode.InvalidRentalHistoryStreetAddress2]: STREET_ADDRESS_2_IS_INVALID,
  [RentalHistoryErrorCode.InvalidRentalHistoryCity]: CITY_IS_INVALID,
  [RentalHistoryErrorCode.InvalidRentalHistoryState]: STATE_IS_INVALID,
  [RentalHistoryErrorCode.InvalidRentalHistoryZipCode]: ZIP_CODE_IS_INVALID,
  [RentalHistoryErrorCode.InvalidRentalHistoryZipCodeRequired]: ZIP_CODE_IS_REQUIRED,
  [RentalHistoryErrorCode.InvalidRentalHistoryCountry]: COUNTRY_IS_INVALID,
};

export enum EmploymentHistoryErrorCode {
  InvalidNameError,
  InvalidPhoneError,
  InvalidSalaryError,
  InvalidPositionError,
  InvalidStartDateError,
  InvalidEndDateError,
  InvalidEndDateEarlierError,
}

export interface ApplicationUpdateRequest {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  ssn?: string;
  dateOfBirth?: string;
  files?: FileDescriptor[];
  interestedUnitIds?: string[];
  history: PresentableRentalHistory[];
  annualIncome?: string;
  employmentHistory: PresentableEmploymentInformation[];
  questions?: QuestionAndAnswers[];
  hasAcceptedMagicDoorTerms?: boolean;
  hasAcceptedTransUnionTerms?: boolean;
}

export interface RentalHistory {
  address?: Address;
  landlordName?: string;
  landlordPhone?: string;
  rent?: number;
  moveInDate?: string;
  moveOutDate?: string;
  reasonForLeaving?: string;
}

export interface FileDescriptor {
  id: string;
  file: File;
  type: FileType;
  name: string;
  wasUploaded: boolean;
  isImage: boolean;
}

export enum ReportStatus {
  NotRequested = 'notRequested',
  Requested = 'requested',
  Available = 'available',
}

export enum ApplicationPaymentStatus {
  Unpaid = 'unpaid',
  Processing = 'processing',
  Paid = 'paid',
  Failed = 'failed',
}

export interface HydratedRentalApplicationFileDto {
  fileId: string;
  type: FileType;
  description?: string | null;
  fileName: string;
  fileExtension: string;
  fileUrl: string;
  thumbUrl?: string | null;
  contentType: string;
  fileSize: number;
}

export interface FileUploadResult {
  fileId: string;
  signedUrl: string;
  thumbSignedUrl: string;
}

export enum FileType {
  Identification = 'identification',
  BankStatement = 'bankStatement',
  EmploymentVerification = 'employmentVerification',
  Additional = 'additional',
}

export interface TransUnionAnswerSheet {
  examId?: number;
  answers?: TransUnionAnswer[];
}

export interface TransUnionAnswer {
  questionKeyName: string;
  selectedChoiceKeyName: string;
}

export enum QuestionType {
  Input = 'input',
  Select = 'select',
  MultiSelect = 'multiSelect',
  Boolean = 'boolean',
}

export interface Question {
  text: string;
  type: QuestionType;
  options?: string[];
  answers: string[];
}

export interface RentalApplicationSettings {
  requiresPayment: boolean;
  paymentAmount: number;
  requiresScreening: boolean;
  questions: Question[];
}

export interface QuestionAndAnswers {
  question: string;
  answers: string[];
}
