export interface MessageFile {
  id: string;
  fileName: string;
  fileExtension: string;
  fileUrl: string;
  thumbUrl: string;
  contentType: string;
  fileSize: number;
}

export function isImage(file: File | MessageFile) {
  const fileType = 'contentType' in file ? file.contentType : file.type;
  return fileType?.startsWith('image/');
}

export function isPdf(file: File | MessageFile) {
  const fileType = 'contentType' in file ? file.contentType : file.type;
  return fileType === 'application/pdf';
}

export function isSvg(file: File | MessageFile) {
  const fileType = 'contentType' in file ? file.contentType : file.type;
  return fileType === 'image/svg+xml';
}

export function isVideo(file: File | MessageFile) {
  const fileType = 'contentType' in file ? file.contentType : file.type;
  return fileType?.startsWith('video/');
}

export function isImageUrl(url: string) {
  return /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(url);
}
