import {
  EMPLOYER_NAME_IS_INVALID,
  END_DATE_IS_INVALID,
  END_DATE_IS_INVALID_EARLIER,
  PHONE_NUMBER_IS_INVALID,
  POSITION_TITLE_IS_INVALID,
  SALARY_IS_INVALID,
  START_DATE_IS_INVALID,
} from '~/assets/strings';
import { Presenter } from '~/framework/Presenter';
import { currency } from '~/global-contexts/utils/number';
import { useLocalization } from '~/hooks/useLocalization';
import { RentalApplicationInfo, User } from '~/state/mainAppState';
import { Address } from '~/types/Address';
import { PresentablePaymentInfo } from '~/types/PresentablePaymentInfo';
import { PresentableUnit } from '~/types/PresentableUnit';
import {
  FileType,
  RentalApplicationErrorCode,
  RentalHistoryErrorCode,
  EmploymentHistoryErrorCode,
  TransUnionScreeningStatus,
  TransUnionScreening,
  applicantInfoErrors,
  errorMessagesForRA,
  errorMessagesForRH,
  QuestionAndAnswers,
  Question,
  QuestionType,
} from '~/types/RentalApplication';
import { PaginatedUnits, Unit } from '~/types/Unit';
import { getRentalHistory } from '~/use-cases/rental-applications/helperUtils';
import { isImageUrl } from '~/utils/fileType';

export interface SelectionValue {
  value: string;
  label: string;
  isSelected: boolean;
}

type UnitsOfInterest = PaginatedUnits<PresentableUnit> & {
  ended?: boolean;
};

export interface PresentableRentalApplication {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  dateOfBirth?: string;
  ssn?: string;
  governmentIdFiles?: PresentableFile[];
  bankStatementFiles?: PresentableFile[];
  annualIncome?: string;
  url?: string;
  rentalHistory?: PresentableRentalHistory[];
  employmentHistory?: PresentableEmploymentInformation[];
  errors?: PresentableRentalApplicationErrors;
  rentalHistoryErrors?: PresentableRentalHistoryError[];
  employmentHistoryErrors?: PresentableEmploymentHistoryError[];
  unitsOfInterest?: UnitsOfInterest;
  isOpenToAllUnits?: boolean;
  unitsListingSearch?: string;
  hasAcceptedMagicDoorTerms?: boolean;
  hasAcceptedTransUnionTerms?: boolean;
  paymentInfo?: PresentablePaymentInfo;
  isPaid?: boolean;
  isLocked?: boolean;
  transUnionScreening?: TransUnionScreening;
  questionsAndAnswers?: PresentableQuestionsAndAnswers[];
}

export interface PresentableTransUnionScreening {
  questions: QuestionAndAnswers[];
  screeningStatus: TransUnionScreeningStatus;
}

export interface PresentableEmploymentInformation {
  name: string;
  phone: string;
  position: string;
  salary?: string;
  startDate?: string;
  endDate?: string;
}

export interface PresentableRentalHistory {
  address?: Address;
  landlordName?: string;
  landlordPhone?: string;
  rent?: string;
  moveInDate?: string;
  moveOutDate?: string;
  reasonForLeaving?: string;
  countries: SelectionValue[];
  states: SelectionValue[];
  selectedCounty?: string;
  selectedState?: string;
}

export interface PresentableRentalApplicationErrors {
  firstNameError: string;
  lastNameError: string;
  emailError: string;
  phoneError: string;
  dateOfBirthError: string;
  ssnError: string;
  annualIncomeError: string;
  magicDoorLicenseError: string;
  transUnionLicenseError: string;
  hasApplicantInfoError: boolean;
  hasRentalHistoryError: boolean;
  hasEmploymentHistoryError: boolean;
  hasLicenseAgreementErrors: boolean;
}

export interface PresentableRentalHistoryError {
  rentalHistoryAddressError: string;
  rentalHistoryStreetAddress1Error: string;
  rentalHistoryStreetAddress2Error: string;
  rentalHistoryReasonForLeavingError: string;
  rentalHistoryCityError: string;
  rentalHistoryStateError: string;
  rentalHistoryZipCodeError: string;
  rentalHistoryCountryError: string;
  rentalHistoryLandlordNameError: string;
  rentalHistoryLandlordPhoneError: string;
  rentalHistoryRentError: string;
  rentalHistoryMoveInDateError: string;
  rentalHistoryMoveOutDateError: string;
}

export interface PresentableEmploymentHistoryError {
  employmentInformationNameError: string;
  employmentInformationPhoneError: string;
  employmentInformationSalaryError: string;
  employmentInformationPositionError: string;
  employmentInformationStartDateError: string;
  employmentInformationEndDateError: string;
}

export interface LabelValuePair {
  label: string;
  value: string;
}

export interface PresentableQuestionsAndAnswers {
  question: string;
  options: LabelValuePair[];
  answers: string[];
  type: QuestionType;
}

export interface PresentableFile {
  id: string;
  path: string;
  name: string;
  type: string;
  isImage: boolean;
}

export class RentalApplicationPresenter extends Presenter<PresentableRentalApplication> {
  protected createModel = (state: User): PresentableRentalApplication | undefined => {
    const rentalApplication = state.rentalApplication.application;
    return {
      firstName: rentalApplication?.firstName,
      lastName: rentalApplication?.lastName,
      email: rentalApplication?.email,
      phoneNumber: rentalApplication?.phone,
      dateOfBirth: rentalApplication?.dateOfBirth,
      ssn: rentalApplication?.ssn,
      governmentIdFiles: this.getFilesOfType(state, FileType.Identification),
      bankStatementFiles: this.getFilesOfType(state, FileType.BankStatement),
      unitsOfInterest: this.getUnitsOfInterest(state),
      isOpenToAllUnits: !state.rentalApplication.application?.interestedUnits?.length,
      unitsListingSearch: state.unitsListing?.search,
      annualIncome: rentalApplication?.annualIncome ? currency(rentalApplication?.annualIncome) : undefined,
      url: `/rental-applications/${rentalApplication?.credentials.id}/${rentalApplication?.credentials.password}`,
      rentalHistory: getRentalHistory(state.rentalApplication.application?.residentialHistory || []),
      employmentHistory: (rentalApplication?.employmentHistory || []).map((history) => {
        return {
          name: history.name,
          phone: history.phone,
          position: history.position,
          salary: currency(history.salary),
          startDate: history.startDate,
          endDate: history.endDate,
        };
      }),
      hasAcceptedMagicDoorTerms: rentalApplication?.hasAcceptedMagicDoorTerms || false,
      hasAcceptedTransUnionTerms: rentalApplication?.hasAcceptedTransUnionTerms || false,
      errors: this.createPresentableRentalApplicationErrors(state),
      rentalHistoryErrors: this.createRentalHistoryErrors(state),
      employmentHistoryErrors: this.createEmploymentHistoryErrors(state),
      paymentInfo: this.cfreatePresentablePaymentInfo(state),
      isPaid: state.rentalApplication.isPaid,
      isLocked: !state.rentalApplication.application?.isDraft,
      transUnionScreening: state.rentalApplication.transUnionScreening,
      questionsAndAnswers: this.createQuestionsAndAnswers(state.rentalApplication),
    };
  };

  private cfreatePresentablePaymentInfo = (state: User): PresentablePaymentInfo => {
    return {
      account: state.rentalApplication.stripe?.account,
      clientSecret: state.rentalApplication.stripe?.clientSecret,
      sessionId: state.rentalApplication.stripe?.sessionId,
      stripeAccountId: state.rentalApplication.stripe?.stripeAccountId || '',
      totalAmount: state.rentalApplication.settings?.paymentAmount || 0,
    };
  };

  private createQuestionsAndAnswers = (rentalApplication: RentalApplicationInfo): PresentableQuestionsAndAnswers[] => {
    const result: PresentableQuestionsAndAnswers[] = [];
    rentalApplication.settings?.questions.forEach((question: Question) => {
      const answers =
        rentalApplication.application?.questionsAndAnswers?.find((qa) => qa.question === question.text)?.answers ||
        (question.options ? [question.options[question.options.length - 1]] : []);
      result.push({
        question: question.text,
        type: question.type,
        options: question.options?.map((option) => ({ label: option, value: option })) ?? [],
        answers,
      });
    });
    return result;
  };

  private getUnitsOfInterest = (state: User): UnitsOfInterest => {
    return {
      pageSize: state.unitsListing?.pageSize,
      currentPage: state.unitsListing?.currentPage,
      totalCount: state.unitsListing?.totalCount,
      totalPages: state.unitsListing?.totalPages,
      ended: Number(state.unitsListing?.currentPage) >= Number(state.unitsListing?.totalPages),
      items:
        state.unitsListing?.items?.map((unit) => {
          return {
            id: unit?.id || '',
            name: unit?.title || '',
            bedrooms: unit?.beds?.toLocaleString() || '',
            bathrooms: unit?.baths?.toLocaleString() || '',
            address: unit.singleLineAddress || '',
            isSelected: unit.id ? state.rentalApplication.application?.interestedUnits?.includes(unit.id) : false,
            area: unit?.unitSizeSqft?.toLocaleString() || '',
            isAvailable: !!unit.listed,
            images: unit.primaryImage ? [unit.primaryImage?.signedThumbUrl ?? ''] : [],
          };
        }) || [],
    };
  };

  private getAddressString = (address?: Address): string => {
    if (!address) {
      return '';
    }
    let addressString = address.streetAddress1 || '';
    if (address.streetAddress2 && address.streetAddress2.length > 0) {
      addressString += `, ${address.streetAddress2}`;
    }
    if (address.city && address.city.length > 0) {
      addressString += `, ${address.city}`;
    }
    if (address.state && address.state.length > 0) {
      addressString += `, ${address.state}`;
    }
    return addressString;
  };

  private getThumbnail = (unit?: Unit): string => {
    if (unit?.images && unit?.images.length > 0) {
      return unit.images[0].signedThumbUrl || '';
    }
    return '';
  };

  private getThumbnailStrings = (unit?: Unit): string[] => {
    return unit?.images?.map((image) => image.signedThumbUrl).filter((url): url is string => url !== undefined) || [];
  };

  private getFilesOfType = (state: User, type: FileType): PresentableFile[] => {
    const rentalApplication = state.rentalApplication.application;
    let files: PresentableFile[] = [];
    if (rentalApplication?.files) {
      files = rentalApplication.files
        .filter((file) => file.type === type)
        .map((file) => {
          return {
            id: file.fileId,
            name: file.fileName,
            type: file.type,
            path: file.thumbUrl ? file.thumbUrl : file.fileUrl,
            isImage: isImageUrl(file.fileUrl),
          } as PresentableFile;
        });
    }
    if (rentalApplication?.newFiles) {
      files = [
        ...files,
        ...rentalApplication.newFiles
          .filter((file) => file.type === type)
          .map((file) => {
            return {
              id: file.id,
              name: file.name,
              type: file.type,
              path: URL.createObjectURL(file.file),
              isImage: file.isImage,
            };
          }),
      ];
    }
    return files;
  };

  private hasApplicantInfoError = (state: User): boolean => {
    const errors = state.rentalApplication.errors || [];
    return errors.some((error) => applicantInfoErrors.includes(error));
  };

  private createPresentableRentalApplicationErrors = (state: User): PresentableRentalApplicationErrors => {
    const { t } = useLocalization();
    const errors = state.rentalApplication.errors || [];

    const presentableErrors = errors.reduce((acc: Record<RentalApplicationErrorCode, string>, errorCode: RentalApplicationErrorCode) => {
      const errorMessage: string = errorMessagesForRA[errorCode];
      if (errorMessage) {
        acc[errorCode] = t(errorMessage);
      }
      return acc;
    }, {} as Record<RentalApplicationErrorCode, string>);

    const getError = (codes: RentalApplicationErrorCode[]) => codes.map((code) => presentableErrors[code]).find(Boolean) || '';

    return {
      firstNameError: getError([RentalApplicationErrorCode.InvalidFirstName]),
      lastNameError: getError([RentalApplicationErrorCode.InvalidLastName]),
      emailError: getError([RentalApplicationErrorCode.InvalidEmail]),
      phoneError: getError([RentalApplicationErrorCode.InvalidPhone]),
      dateOfBirthError: getError([
        RentalApplicationErrorCode.InvalidDateOfBirth,
        RentalApplicationErrorCode.InvalidDateOfBirthGreater,
        RentalApplicationErrorCode.InvalidDateOfBirthLesser,
      ]),
      annualIncomeError: getError([RentalApplicationErrorCode.InvalidAnnualIncome]),
      ssnError: getError([RentalApplicationErrorCode.InvalidSSN]),
      magicDoorLicenseError: getError([RentalApplicationErrorCode.InvalidMagicDoorAgreement]),
      transUnionLicenseError: getError([RentalApplicationErrorCode.InvalidTransUnionAgreement]),
      hasApplicantInfoError: this.hasApplicantInfoError(state),
      hasRentalHistoryError: this.hasRentalHistoryError(state),
      hasEmploymentHistoryError: this.hasEmploymentHistoryError(state),
      hasLicenseAgreementErrors: this.hasLicenseAgreementErrors(state),
    };
  };

  private createRentalHistoryErrors = (state: User): PresentableRentalHistoryError[] => {
    const result: PresentableRentalHistoryError[] = [];
    const { t } = useLocalization();

    const errorMap = {
      rentalHistoryAddressError: [RentalHistoryErrorCode.InvalidAddress],
      rentalHistoryStreetAddress1Error: [RentalHistoryErrorCode.InvalidRentalHistoryStreetAddress1],
      rentalHistoryStreetAddress2Error: [RentalHistoryErrorCode.InvalidRentalHistoryStreetAddress2],
      rentalHistoryCityError: [RentalHistoryErrorCode.InvalidRentalHistoryCity],
      rentalHistoryReasonForLeavingError: [RentalHistoryErrorCode.InvalidRentalHistoryReasonForLeaving],
      rentalHistoryStateError: [RentalHistoryErrorCode.InvalidRentalHistoryState],
      rentalHistoryZipCodeError: [RentalHistoryErrorCode.InvalidRentalHistoryZipCode],
      rentalHistoryCountryError: [RentalHistoryErrorCode.InvalidRentalHistoryCountry],
      rentalHistoryLandlordNameError: [RentalHistoryErrorCode.InvalidRentalHistoryLandlordName],
      rentalHistoryLandlordPhoneError: [RentalHistoryErrorCode.InvalidRentalHistoryLandlordPhone],
      rentalHistoryRentError: [RentalHistoryErrorCode.InvalidRentalHistoryRent],
      rentalHistoryMoveInDateError: [
        RentalHistoryErrorCode.InvalidRentalHistoryMoveInDate,
        RentalHistoryErrorCode.InvalidRentalHistoryMoveInDateLater,
      ],
      rentalHistoryMoveOutDateError: [
        RentalHistoryErrorCode.InvalidRentalHistoryMoveOutDate,
        RentalHistoryErrorCode.InvalidRentalHistoryMoveOutDateEarlier,
      ],
    };

    state.rentalApplication.rentalHistoryErrors.forEach((historyError) => {
      const errorObject = {} as PresentableRentalHistoryError;

      Object.keys(errorMap).forEach((key) => {
        const errorCodes = errorMap[key as keyof typeof errorMap];
        errorObject[key as keyof PresentableRentalHistoryError] =
          errorCodes.map((errorCode) => (historyError.includes(errorCode) ? t(errorMessagesForRH[errorCode]) : '')).find(Boolean) || '';
      });

      result.push(errorObject);
    });

    return result;
  };

  private createEmploymentHistoryErrors = (state: User): PresentableEmploymentHistoryError[] => {
    const result: PresentableEmploymentHistoryError[] = [];
    const { t } = useLocalization();
    state.rentalApplication.employmentHistoryErrors.forEach((historyError) => {
      const errorObject: PresentableEmploymentHistoryError = {
        employmentInformationNameError: historyError.includes(EmploymentHistoryErrorCode.InvalidNameError)
          ? t(EMPLOYER_NAME_IS_INVALID)
          : '',
        employmentInformationPhoneError: historyError.includes(EmploymentHistoryErrorCode.InvalidPhoneError)
          ? t(PHONE_NUMBER_IS_INVALID)
          : '',
        employmentInformationPositionError: historyError.includes(EmploymentHistoryErrorCode.InvalidPositionError)
          ? t(POSITION_TITLE_IS_INVALID)
          : '',
        employmentInformationSalaryError: historyError.includes(EmploymentHistoryErrorCode.InvalidSalaryError) ? t(SALARY_IS_INVALID) : '',
        employmentInformationStartDateError: historyError.includes(EmploymentHistoryErrorCode.InvalidStartDateError)
          ? t(START_DATE_IS_INVALID)
          : '',
        employmentInformationEndDateError: historyError.includes(EmploymentHistoryErrorCode.InvalidEndDateError)
          ? t(END_DATE_IS_INVALID)
          : historyError.includes(EmploymentHistoryErrorCode.InvalidEndDateEarlierError)
          ? t(END_DATE_IS_INVALID_EARLIER)
          : '',
      };
      result.push(errorObject);
    });
    return result;
  };

  private hasRentalHistoryError = (state: User): boolean => {
    return (
      state.rentalApplication.rentalHistoryErrors.length > 0 &&
      state.rentalApplication.rentalHistoryErrors.some((subarray) => subarray.length > 0)
    );
  };

  private hasEmploymentHistoryError = (state: User): boolean => {
    return (
      (state.rentalApplication.employmentHistoryErrors.length > 0 &&
        state.rentalApplication.employmentHistoryErrors.some((subarray) => subarray.length > 0)) ||
      state.rentalApplication.errors.includes(RentalApplicationErrorCode.InvalidAnnualIncome)
    );
  };

  private hasLicenseAgreementErrors = (state: User): boolean => {
    return (
      state.rentalApplication.errors.includes(RentalApplicationErrorCode.InvalidTransUnionAgreement) ||
      state.rentalApplication.errors.includes(RentalApplicationErrorCode.InvalidMagicDoorAgreement)
    );
  };
}
