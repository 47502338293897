import { currency } from '~/global-contexts/utils/number';
import { PresentableRentalHistory, SelectionValue } from '~/presenters/RentalApplicationPresenter';
import { RentalHistory } from '~/types/RentalApplication';
import { statesAndProvincesMap } from './countries';

export const getStates = (selectedCountry?: string, selectedState?: string): SelectionValue[] => {
  const states: SelectionValue[] = [];
  const targetCountry = selectedCountry || 'USA';
  const stateData = (statesAndProvincesMap as Record<string, { abbreviations: string[]; fullNames: string[] }>)[targetCountry];

  if (stateData) {
    const stateArr = stateData.abbreviations;
    const stateNames = stateData.fullNames;

    stateArr.forEach((state, index) => {
      states.push({
        value: state,
        label: stateNames[index],
        isSelected: selectedState ? state === selectedState : index === 0,
      });
    });
  }

  return states;
};

export const getCountries = (selectedCountry?: string): SelectionValue[] => {
  const countries: SelectionValue[] = [];
  for (const country of Object.keys(statesAndProvincesMap)) {
    countries.push({
      value: country,
      label: country,
      isSelected: selectedCountry ? country === selectedCountry : country === 'USA',
    });
  }
  return countries;
};

export const getRentalHistory = (history: RentalHistory[]): PresentableRentalHistory[] => {
  if (!history || history.length === 0) {
    return [];
  }
  return history.map((rentalHistory) => {
    const countries = getCountries(rentalHistory.address?.country);
    const states = getStates(rentalHistory.address?.country, rentalHistory.address?.state);
    return {
      ...rentalHistory,
      rent: rentalHistory.rent ? currency(rentalHistory.rent) : '',
      countries,
      states,
      selectedCounty: countries.find((country) => country.isSelected)?.value,
      selectedState: states.find((state) => state.isSelected)?.value,
    };
  });
};
